@import '../node_modules/modern-normalize/modern-normalize.css';

.App {
  overflow: hidden !important;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  background: #04F404;
}

.App svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#TempButtons {
  position: fixed;
  z-index: 9999;
}

#FullScreenImage {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#FullScreenImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Ziggo */

.fill-orange {
  fill: #f08800;
}

.fill-darkorange {
  fill: #ff6700;
}

.fill-black {
  fill: #141415;
}

.bg-black {
  background: #141415;
}

.fill-dark {
  fill: #2c2d2d;
}

.fill-white {
  fill: #ffffff;
}

.fill-opacity-65 {
  fill-opacity: .65;
}

.stroke-gray {
  stroke: #424445;
}

.bg-black{
  background: #141415;
}

.font-diodrum {
  font-family: Diodrum-Semibold, Diodrum;
}

.font-diodrum-mono {
  font-family: DiodrumSemiboldMonoDigits, Diodrum-SemiBold-MonoDigits;
}

.font-semi {
  font-weight: 600;
}

.text-2xl {
  font-size: 70px;
}

.text-lg {
  font-size: 42px;
}

.text-md {
  font-size: 36px;
}

.text-sm {
  font-size: 28px;
}

/* Components */

.Panel {
  fill:url(#gradientCircularGray);
}